// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-arthur-en-yvonne-js": () => import("./../src/pages/portfolio/arthur-en-yvonne.js" /* webpackChunkName: "component---src-pages-portfolio-arthur-en-yvonne-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-maarten-en-heiline-js": () => import("./../src/pages/portfolio/maarten-en-heiline.js" /* webpackChunkName: "component---src-pages-portfolio-maarten-en-heiline-js" */),
  "component---src-pages-portfolio-manuel-en-marleen-js": () => import("./../src/pages/portfolio/manuel-en-marleen.js" /* webpackChunkName: "component---src-pages-portfolio-manuel-en-marleen-js" */),
  "component---src-pages-portfolio-martijn-en-marscha-js": () => import("./../src/pages/portfolio/martijn-en-marscha.js" /* webpackChunkName: "component---src-pages-portfolio-martijn-en-marscha-js" */),
  "component---src-pages-portfolio-mathijs-en-noortje-js": () => import("./../src/pages/portfolio/mathijs-en-noortje.js" /* webpackChunkName: "component---src-pages-portfolio-mathijs-en-noortje-js" */),
  "component---src-pages-portfolio-omer-en-ayse-js": () => import("./../src/pages/portfolio/omer-en-ayse.js" /* webpackChunkName: "component---src-pages-portfolio-omer-en-ayse-js" */),
  "component---src-pages-tarieven-js": () => import("./../src/pages/tarieven.js" /* webpackChunkName: "component---src-pages-tarieven-js" */)
}

